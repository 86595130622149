<template>
  <v-container fluid>
    <v-container class="component-container">
      <v-row>
        <p class="h2-bold"> News and media. Here you can read some of the cases <br>
          <span style="font-weight: 300"> that have been written about us and the topic</span>
        </p>
      </v-row>
      <v-row style="margin-top: 3em">
        <v-col v-for="article in articles" :key="article.image" cols="12" lg="4">

          <a :href="article.link">
            <NewsCard :image="article.image" :header="article.header"
                      :content="article.content"/>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import NewsCard from "@/pages/LandingPage/NewsCard";

export default {
  name: "NewsArticles",
  components: {NewsCard},
  data: () => ({
    articles: [{
      image: "articles/forskningno_article.jpg",
      header: "Dataprogram skal varsle om hjerneslag",
      content: "Hvert år kommer 1500 nordmenn med hjerneslag for sent til behandling. En algoritme som kan kjenne igjen ansiktslammelse, vil slå alarm om du har fått slag. – Håpet er å redde liv, sier gründeren bak programmet.",
      link: "https://forskning.no/hjernen-sykdommer-teknologi/dataprogram-skal-varsle-om-hjerneslag/1640984"
    }, {
      image: "articles/simula_article.jpg",
      header: "SimulaMet partners with LHL hjerneslag, ResQ Biometrics and Stavanger University Hospital",
      content: "The new project is funded by stiftelsen DAM and will use artificial intelligence for instant recognition of strokes. ",
      link: "https://www.simulamet.no/content/simulamet-partners-lhl-hjerneslag-resq-biometrics-and-stavanger-university-hospital"
    }, {
      image: "articles/lhl_article.jpg",
      header: "Kunstig intelligens skal avdekke hjerneslag",
      content: "Kan kunstig intelligens tas i bruk for å gjenkjenne hjerneslag? Ja, mener gründerne bak selskapet ResQ Biometrics AS, som nå er i gang med å samle inn bilder av flere tusen slagrammede. I framtida tror de ansiktsskanning kan oppdage slag.",
      link: "https://www.lhl.no/lhl-hjerneslag/aktuelt/kunstig-intelligens-skal-avdekke-hjerneslag/"
    }]
  })
}
</script>

<style scoped>

</style>
