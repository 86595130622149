<template>
  <v-container fluid>
    <v-container class="component-container">
      <v-row>
        <v-col cols="12" md="7">
          <p class="text-right h2-bold">
            Early warnings save lives and <br>
            minimize the risk of brain damage
            <br>
            <span style="color: #85C0E5">
            ResQ Biometrics can make <br> the big difference
          </span>
          </p>
        </v-col>
        <v-col md="1"/>
        <v-col cols="12" md="4">
          <p class="paragraph-regular">
            Along with saving lives - our <br>
            purpose is to alert early <br>
            enough, for a full recovery to <br>
            be achievable. Because every <br>
            minute counts.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <v-img v-if="!onMobile" :src="desktopFigure" width="80%"/>
          <v-container v-if="onMobile">
            <v-img :src="mobileFigure1"/>
            <v-img :src="mobileFigure2"/>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "ProductPromotion",
  data: () => ({
    desktopFigure: "graphics/resq-figure-desktop.png",
    mobileFigure1: "graphics/resq-figure-mobile-1.png",
    mobileFigure2: "graphics/resq-figure-mobile-2.png",
  }),
  computed: {
    onMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
        case 'md':
          return true
        default:
          return false
      }
    },
  },
}
</script>

<style scoped>

</style>
