<template>
  <v-container class="secondary-button-regular">
    <img class="mr-3" style="vertical-align:middle" :src="right_arrow_image" alt="image of an arrow point to the right">
    {{ text }}
  </v-container>
</template>

<script>
export default {

  name: "NavigationButton",
  props: {text: String},
  data: () => ({
    right_arrow_image: "right-arrow.png"
  })

}
</script>

<style scoped>

</style>
