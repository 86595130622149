import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from "./router/router";
import WebCam from 'vue-web-cam'
import axios from "axios";
import './assets/style.css';
import VueApexCharts from 'vue-apexcharts'
import Vuex from 'vuex'


Vue.config.productionTip = false

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        displayPageTop: true,
        demoIsLocked: true
    },
    mutations: {
        hidePageTop (state) {
            state.displayPageTop = false
        },
        showPageTop (state) {
            state.displayPageTop = true
        },
        unlockDemo (state) {
            state.demoIsLocked = false
        },
        lockDemo (state) {
            state.demoIsLocked = true
        }
    }
})

new Vue({
    vuetify,
    router,
    axios,
    VueApexCharts,
    WebCam,
    store,
    render: h => h(App)
}).$mount('#app')


