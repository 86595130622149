<template>
  <header style="width: 100%; position: absolute; padding-bottom: 20px">
    <div class="d-flex align-center justify-center" style="width:100%;  height: 176px;">
      <div class="d-flex align-center justify-space-between" style="max-width:1150px; width: 100%;">
        <router-link to="/" custom style="z-index: 10; margin-left: 2em;">
          <img height="92px" src="https://i.postimg.cc/d3C1hs8p/Resq-Biometrics-logo-lq.png" alt="Resq Biometrics Logo"
               @click="$store.commit('showPageTop')">
        </router-link>
        <!--      <v-btn text to="/team">-->
        <!--        Team-->
        <!--      </v-btn>-->
        <v-btn text to="/demo" @click="$store.commit('hidePageTop')" class="d-none d-sm-flex"
               style="font-style: normal; z-index: 10;font-weight: normal;font-size: 20px;line-height: 24px;letter-spacing: -0.81px; color: #FFFFFF;
margin-right: 5em ">
          Demo
        </v-btn>

      </div>
    </div>
  </header>

</template>

<script>
export default {
  name: "NavBar"
}
</script>

<style scoped>

</style>
