<template>
  <router-link :to="link">
    <NavigationButton :text="text"></NavigationButton>
  </router-link>
</template>

<script>
import NavigationButton from "@/components/NavigationButton";
export default {
  name: "NavigationButtonLocal",
  components: {NavigationButton},
  props: {text: String, link:String},
}
</script>

<style scoped>

</style>
