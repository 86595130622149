<template>
  <div>
    <div id="chart">
      <apexchart type="radar" height="500" :options="chartOptions" :series="series"></apexchart>
    </div>
  </div>
</template>

<script>

import VueApexCharts from "vue-apexcharts";


export default {
  name: "prediction-chart",
  components: {
    apexchart: VueApexCharts,
  },
  props: [
    'modelOutput'
  ],
  data() {
    return {
      series: [{
        data: [this.modelOutput[0], /*this.modelOutput[1],*/ this.modelOutput[2], this.modelOutput[3], this.modelOutput[4], /* this.modelOutput[5],*/ this.modelOutput[6]],
      }],
      chartOptions: {
        chart: {
          animations: {
            enabled: false,
            easeing: "linear",
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          },
          height: 0,
          type: 'radar',
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: ['Angry', /*'Disgust',*/ 'Fear', 'Happy', 'Neutral', /*'Sad',*/ 'Surprised'],
          labels: {style: {fontSize: 15}}
        },
        yaxis: {
          show: false,
          max: 1,

        },
        tooltip: {enabled: false},
      },


    }
  },

}
</script>

<style scoped>

</style>
