<template>
  <v-container style="position: relative">
    <v-container style="position: absolute; top: 10vh;" class="component-wrapper">
      <v-container class="component-container">
        <LoginForm v-if="demoIsLocked"/>
        <v-row style="max-width: 1350px;">
          <v-col v-if="!demoIsLocked" cols="auto" class="col-md-6">
            <p class="h3-bold" style="display: none">Current Camera</p>
            <p class="h3-bold">Model Input</p>
            <figure v-if="show_output && face_detected" class="figure">
              <img :src="'data:image/jpeg;base64,'+img_2" class="img-responsive" style="width:100%"/>
            </figure>
            <div class="border" :style="face_detected">
              <vue-web-cam
                  ref="webcam"
                  :device-id="deviceId"
                  width="100%"
                  height="100%"
                  @cameras="onCameras"
                  @camera-change="onCameraChange"
              />
            </div>
            <div>
              <v-btn class="secondary-button-regular" v-if="show_predict_button" @click="onCapture">Predict</v-btn>
              <v-btn class="secondary-button-regular" v-if="this.cameraOn" @click="onStop">Stop Camera</v-btn>
              <v-btn class="secondary-button-regular" v-else @click="onStart">Start Camera</v-btn>
            </div>
          </v-col>
          <v-col v-if="!demoIsLocked" cols="auto" class="col-md-6">
            <p class="h3-bold">Prediction</p>
            <prediction-chart :model-output="moving_avg" :key="componentKey"></prediction-chart>
          </v-col>
          <!--      <v-col v-if="!locked " xl="4" xs="12">-->

          <!--      </v-col>-->
        </v-row>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import {WebCam} from 'vue-web-cam'
import axios from 'axios'
import PredictionChart from "@/pages/DemoPage/PredictionChart";
import LoginForm from "./LoginForm";

export default {
  name: "App",
  components: {
    LoginForm,
    PredictionChart,
    "vue-web-cam": WebCam
  },
  mounted() {
    this.$store.commit("hidePageTop")
  },
  destroyed() {
    clearInterval(this.interval)
    this.$store.commit('lockDemo')
  },
  data() {
    return {
      face_detected: "",
      suggestedPassword: '',
      locked: true,
      componentKey: 0,
      cameraOn: true,
      img: null,
      img_2: null,
      text_result: '',
      text_pred_time: '',
      test_txt_im: '',
      camera: null,
      deviceId: null,
      devices: [],
      url: 'https://resqdemo.online/tf_server_rest',
      show_predict_button: true,
      displayCapture: false,
      interval: null,
      show_output: false,
      prediction: [0, 0, 0, 0, 0, 0, 0],
      predictions: [[0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0]],
      moving_avg: [0, 0, 0, 0, 0, 0, 0],
      steps_per_avg: 5,
      step: 0
    };
  },
  computed: {
    device: function () {
      return this.devices.find(n => n.deviceId === this.deviceId);
    },
    demoIsLocked() {
      return this.$store.state.demoIsLocked
    }
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      const [first] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    }
  },
  methods: {
    checkSecret() {
      if (this.suggestedPassword === "rqb2021") {
        // if (this.suggestedPassword === " ") {
        this.locked = false
      }
    },
    update_moving_avg() {

      for (let i = 0; i < 7; i++) {
        this.predictions[i][this.step % this.steps_per_avg] = parseFloat(this.prediction[i])
        this.moving_avg[i] = this.average(this.predictions[i])
      }
      this.step++

    },
    average(arr) {
      return arr.reduce((p, c) => p + c, 0) / arr.length;
    },
    onCapture() {
      this.show_predict_button = false
      this.show_output = true

      this.interval = window.setInterval(this.handleCapture, 250)

    },


    handleCapture() {
      this.img = this.$refs.webcam.capture();
      // this.text_result = 'Predicting..'
      this.text_pred_time = ''

      let config = {
        headers: {'Content-Type': 'application/json'}
      }
      let data = {
        'image': this.img,
      }


      axios
          .post(this.url, data, config)
          .then(response => {
            this.text_result = response['data']['pred_txt']
            this.text_pred_time = response['data']['pred_time']
            if (this.text_pred_time) {
              this.img_2 = response['data']['image_out'].slice(2, -1) //ghetto fix
            }
            this.prediction = JSON.parse(response['data']['prediction_vector'])['predictions'][0]
            // this.prediction = this.prediction.map(e => e.toString())
            if (this.prediction === undefined) {
              this.face_detected = "display: none"
            } else {
              this.face_detected = ""
            }
            console.log("Face detected:")
            console.log(this.face_detected)

            this.update_moving_avg()
            this.componentKey++
          })
    },
    onStop() {

      this.show_predict_button = false
      this.show_output = false
      clearInterval(this.interval)
      this.cameraOn = false
      this.$refs.webcam.stop();
    },
    onStart() {
      this.show_predict_button = true
      this.cameraOn = true
      this.$refs.webcam.start();
      this.onCapture()
    },
    onCameras(cameras) {
      this.devices = cameras;
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
    }
  }
};
</script>
