<template>
  <v-container>
    Team Page
  </v-container>
</template>

<script>
export default {
name: "Us"
}
</script>

<style scoped>

</style>
