<template>
  <a :href="link" target="_blank">
    <NavigationButton :text="text"/>
  </a>
</template>

<script>
import NavigationButton from "@/components/NavigationButton";
export default {
  name: "NavigationButtonExternal",
  components: {NavigationButton},
  props: {text: String, link:String}
}
</script>

<style scoped>

</style>
