<template>
  <v-container fluid id="development-info">
    <v-container class="component-container">
      <v-row>
        <v-col>
          <p class="h2-bold">ResQ Biometrics - the early <br>
            warning system, <span style="color: #F8ACBF"> that will save lives effortlessly </span>
          </p>
          <br>
          <br>
          <v-row class="align-center ">
            <v-col cols="2" md="1">
              <v-img :src="contents[0].image" width="100%"/>
            </v-col>
            <v-col class="h3-bold">
              {{ contents[0].title }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="0" md="1"/>
            <v-col cols="12" md="8">
              <p class="paragraph-regular">{{ contents[0].text }}</p>
            </v-col>
            <v-col cols="0" md="2"/>
          </v-row>
          <v-row class="align-center ">
            <v-col cols="2" md="1">
              <v-img :src="contents[1].image" width="100%"/>
            </v-col>
            <v-col class="h3-bold">
              {{ contents[1].title }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="0" md="1"/>
            <v-col cols="12" md="8">
              <p class="paragraph-regular">{{ contents[1].text }}</p>
              <NavigationButtonLocal class="secondary-button-regular" text="Demo" link="/demo"/>
            </v-col>
            <v-col cols="0" md="2"/>
          </v-row>
          <br>
          <br>
          <br>
        </v-col>
        <v-col cols="12" lg="5">
          <lottie-animation path="Slag.json"
                            :loop="true"
                            :autoPlay="true"
                            :speed="0.6"/>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import NavigationButtonLocal from "@/components/NavigationButtonLocal";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: "DevelopmentInfo",
  components: {
    NavigationButtonLocal,
    LottieAnimation
  },
  data: () => ({
    contents: [
      {
        image: 'ai.png',
        title: "Why",
        text: "What if we could warn you, when you are unaware of what is about to happen? What if the outcome most likely is brain damage or death? ResQ Biometrics is developing an ultra-early warning system that will alert as soon as signs of a stroke occurs. This will allow patients to get rapid treatment and a better chance of a full recovery."
      },
      {
        image: 'head.png',
        title: "How",
        text: "We are creating a unique dataset to train our model with data from Norwegian hospitals. The project is led by Stavanger University Hospital with expertise from  SimulaMet. "
      }
    ],
  }),
}
</script>

<style scoped>

</style>
