<template>
  <v-container fluid>
    <v-container class="component-container">
      <v-row>
        <v-col class="d-none d-sm-flex" cols="12" lg="6" align="center" style="position: relative; height: 600px; left: -1em; top: -100px">
          <lottie-animation
              style="position: absolute;"
              path="Hjerne.json"
              :loop="true"
              :autoPlay="true"
              :speed="1"
              :height="600"
          />
        </v-col>
        <v-col>
          <p class="mt-10 h2-bold">Stroke is the world's second <br><span style="color: #66D9DC"> most frequent cause of death</span>
          </p>
          <p class="mt-10 paragraph-regular"
          > <!-- So what is stroke? <br> -->
            Stroke is taking over 6 million lives annually.
            A person suffering from stroke is losing two million brain cells every minute and is,
            in most cases, unaware of the ongoing stroke due to lack of knowledge of stroke symptoms.
          </p>
          <NavigationButtonExternal class="secondary-button-regular" link="https://en.wikipedia.org/wiki/Stroke"
                                    text="Read more"/>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import NavigationButtonExternal from "@/components/NavigationButtonExternal";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";


export default {
  name: 'StrokeInfo',
  components: {
    NavigationButtonExternal,
    LottieAnimation
  },
  data: () => ({
    brain_image: "graphics/brain.png",
  }),
}
</script>
