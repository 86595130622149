import VueRouter from "vue-router";

import Vue from "vue";
import Landing from "@/pages/LandingPage";
import Demo from "@/pages/DemoPage";
import Team from "@/pages/TeamPage";

const routes = [
    {
        path: "/",
        component: Landing,
        name: "Landing",
    },
    {
        path: "/demo",
        component: Demo,
        name: "Demo",
    },
    {
        path: "/team",
        component: Team,
        name: "Team",
    },
];

Vue.use(VueRouter);

export const router = new VueRouter({
    routes,
    mode: "history",
});
export default router;
