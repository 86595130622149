<template>
  <v-container fluid pa-0>
    <PartnersRow class="d-none d-md-flex"/>
    <StrokeInfo class="component-wrapper white-background"/>
    <DevelopmentInfo class="component-wrapper"/>
    <ProductPromotion class="component-wrapper white-background"/>
    <NewsArticles class="component-wrapper"/>
    <PageFooter class=""/>
  </v-container>
</template>

<script>
import PartnersRow from "./PartnersRow";
import StrokeInfo from "./StrokeInfo";
import DevelopmentInfo from "./DevelopmentInfo";
import ProductPromotion from "./ProductPromotion";
import NewsArticles from "./NewsArticles";
import PageFooter from "./PageFooter";

export default {
  name: 'Landing',
  mounted() {
    this.$store.commit("showPageTop")
  },
  components: {PageFooter, NewsArticles, ProductPromotion, DevelopmentInfo, StrokeInfo, PartnersRow},
}
</script>



