<template>

  <div class="custom-container" :style="height">

    <div style="width: 1150px; height: 100%; margin: auto; position: relative; ">
      <div style="width: 1150px; height: 100%; position: absolute; top: 250px; z-index: 1000">
        <p>
          <span class="h1-bold">
          Utilizing artificial <br> intelligence
          </span>
          <span class="h1-light">
            to <br class="d-flex d-lg-none"> save <br class="d-none d-lg-flex"> human <br class="d-flex d-lg-none">intelligence.
          </span>
        </p>
        <!--        <NavigationButtonLocal link="/team" text="Team"/>-->
      </div>
    </div>

    <div class="d-none d-sm-flex" style="width: 49%; height:120%; position: absolute; right: 0; top: 0;">
      <img :src="particles" alt="">
    </div>
  </div>
</template>
<script>


export default {
  name: 'PageTop',
  data: () => ({
    particles: "graphics/particles.png"
  }),
  computed: {
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
        case 'md':
          return "height: 75vh"

        default:
          return "height: 85vh"
      }
    },
  },
}
</script>
<style scoped>
.custom-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-left: 2em
}
</style>
