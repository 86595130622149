<template>

  <v-app style="background-color: rgb(246,249,251)" id="app">

    <link rel="preconnect" href="https://fonts.gstatic.com">
    <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet">    <link href="assets/style.css" rel="stylesheet" type="text/css">

    <NavBar/>

    <PageTop v-if="displayPageTop" class="white-background"/>
    <v-main>
      <v-container fluid pa-0>
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>

import NavBar from "./components/NavBar";
import PageTop from "./pages/LandingPage/PageTop";

export default {
  name: 'App',
  components: {
    PageTop,
    NavBar
  },
  computed: {
    displayPageTop() {
      return this.$store.state.displayPageTop
    }
  }
};


</script>
