<template>
  <v-container>
    <v-hover v-slot:default="{ hover }">
      <v-container>
        <v-img :src="image" class="rounded-card"
               :gradient="hover ? 'to top, rgba(194, 226, 242, 1) 0%, transparent 20%' : 'to top, rgba(194, 226, 242, 1) 0%, transparent 40%'"
               :style="hover ? 'opacity: 1' : 'opacity: 0.7' "/>

        <p class="h3-bold" style="margin-top: 2em">{{ header }}</p>
        <p class="paragraph-regular"> {{ content }}</p>
      </v-container>
    </v-hover>

  </v-container>
</template>

<script>
export default {
  name: "NewsCard",
  props: ['image', 'header', 'content']
}
</script>

<style scoped>
.rounded-card {
  border-radius: 20px;
}
</style>
