<template>
  <v-container class="component-container">
    <p class="h3-bold"> The Demo is not yet accessible to all visitors</p>
    <label class="h3-bold"> Log in </label>
    <v-divider/>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation>
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="password"
                label="Password"
                :rules="passwordRules"
                required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col  cols="12" sm="10" md="11" class="error--text theme--light">
            {{status}}
          </v-col>
          <v-col  cols="12" sm="2" md="1">
            <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4"
                @click="validate"
            >
              Log in
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>
<script>
export default {
  name: 'LoginForm',
  data: () => ({
    valid: false,
    password: '',
    email: '',
    status: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    passwordRules: [
      v => !!v || 'Password is required',
    ]
  }),
  methods: {
    validate() {
      this.$refs.form.validate()
      if (this.password !== "rqb2021" && this.valid){
        this.status = "E-mail and password does not match"
      } else {
        this.status = ""
        this.$store.commit("unlockDemo")
      }
    },
  },
}
</script>