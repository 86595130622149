<template>
  <v-container fluid>
    <v-container class="component-container">
      <v-row align="center" justify="center">
        <v-col cols="2" v-for="partner in partnersData" :key="partner.key">
          <v-hover v-slot:default="{ hover }">
            <a :href="partner.link">
              <v-img :alt="partner.link" height="12vh" contain :src="partner.file"
                     :style="hover ? 'opacity: 1' : 'opacity: 0.5' "/>
            </a>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
export default {
  name: 'Partners',
  data: () => ({
    partnersData: [
      {
        file: 'partner-logos/helse-stavanger.png',
        link: "https://helse-stavanger.no/",
      },
      {
        file: 'partner-logos/lhl.png',
        link: "https://www.lhl.no/lhl-hjerneslag/",
      },
      {
        file: 'partner-logos/innovasjon-norge.png',
        link: "https://www.innovasjonnorge.no/",
      },
      {
        file: 'partner-logos/nht.png',
        link: "https://www.norwayhealthtech.com/",
      },
      {
        file: 'partner-logos/stiftelsen-dam.png',
        link: "https://dam.no/",
      },
    ]
  })
}
</script>
