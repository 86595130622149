<template>
  <v-container class="component-container">
    <v-footer style="justify-content: center" align-center>
      <v-row class="align-items-center">
        <p class="paragraph-regular">Made by Tembo in cooperation with Resq Biometrics</p>
        <v-spacer></v-spacer>
        <p class="paragraph-regular">
          You can find us here:
        </p>
        <a href="https://www.linkedin.com/company/resq-biometrics/%22%3E">
          <v-img contain :src="linkedinImage"/>
        </a>
      </v-row>
    </v-footer>
  </v-container>
</template>
<script>
export default {
  name: 'PageFooter',
  data: () => ({
    linkedinImage: "linkedin.png"
  })
}
</script>